// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger)


document.body.classList.add("scroll_off")
let logoLoader = document.querySelector(".preloader__logo--target")
let iconPreloader = document.querySelector(".preloader__scroll > *")
let logoMain = document.querySelector(".header__site-logo--target")
gsap.set(logoMain, { autoAlpha: 0 })


gsap.set(".menu-burger", { autoAlpha: 0 })
gsap.set(".header__column--right", { autoAlpha: 0 })
gsap.set(logoLoader, { transformOrigin: "0% 0%" })
gsap.to(".main_wrapper", { opacity: 1 })
window.autoPlayScrollAnim_flag = false

let loadedLogo, preloaderTl, preloaderSt, loadingLogo
ScrollTrigger.defaults({ scroller: ".smoothScroll" })


function preloaderFun() {

    let logoMainPos = logoMain.getBoundingClientRect()
    let logoLoaderPos = logoLoader.getBoundingClientRect()
    let iconPreloaderPos = iconPreloader.getBoundingClientRect()

    let tempParam = {
        x: -(logoLoaderPos.left - logoMainPos.left),
        y: -(logoLoaderPos.top - iconPreloaderPos.height / 2 - logoMainPos.top),
        scale: logoMain.width / logoLoaderPos.width,
    }
    loadedLogo = gsap.timeline({ paused: true })
        .to(loadingLogo, { duration: 3, progress: 1, ease: "none" })
        .to("body:not(.privatePage) #gByBank", { duration: 0.5, autoAlpha: 1, x: 0, y: 0, ease: "power1.out" }, ">-1.5")
        .to("body:not(.privatePage) #gLogoLetLast", { duration: 0.5, scaleY: 0.72, transformOrigin: "50% 0%", ease: "power1.out" }, "<")
        .to(logoLoader, { duration: 0.2, y: -10, scale: 1.0, repeat: 1, yoyo: true, transformOrigin: "50% 50%", ease: "power1.out" }, "<")
        .to(".preloader__scroll", { height: iconPreloaderPos.height }, ">")
        .to(".preloader__scroll", { autoAlpha: 1 }, ">-0.2")
        .to("body", { overflowY: "auto" }, "<")



    preloaderTl = gsap.timeline({ paused: true, defaults: { ease: "none" } })
        .call(function () { document.body.classList.remove("scroll_off") })
        .to(logoLoader, { duration: 3, x: tempParam.x, y: tempParam.y, scale: tempParam.scale, transformOrigin: "0% 0%", ease: 'back.out(0.5)' }, 0.1)
        .to(".preloader", { background: "rgba(255,255,255,0)", ease: "sine.out" }, "<")
        .to(".preloader__scroll > *", { autoAlpha: 0 }, "<")
        .to("#gByBank > *", { duration: 0.2, autoAlpha: 0, x: 0, y: 20, ease: "power1.out" }, "<")
        .to("#gLogoLetLast", { duration: 0.2, scaleY: 1, transformOrigin: "50% 0%", ease: "power1.out" }, "<")
        .set(".preloader", { autoAlpha: 0 })
        .set(logoMain, { autoAlpha: 1 })
        .to(".menu-burger", { autoAlpha: 1 })
        .to(".header__column--right", { autoAlpha: 1 }, "<")


    if (document.body.classList.contains("preloaderScroll")) {
        gsap.timeline()
            .to(loadedLogo, { duration: 3, progress: 1, ease: "none" })
            .call(function () { document.body.classList.remove("scroll_off") }, null, ">-0.8")


        preloaderSt = ScrollTrigger.create({
            animation: preloaderTl,
            trigger: "body",
            // trigger: ".scroll_container",
            // scroller: ".main_wrapper",
            start: "top top",
            end: "top+=150px top",
            scrub: 1,
            // toggleActions: "restart none reverse none",
            // markers: true,
        })
    } else {
        gsap.to(loadedLogo, {
            duration: 1, progress: 1, ease: "none",
            onComplete: function () {
                preloaderTl.restart()
            }
        })
    }
}

document.addEventListener("DOMContentLoaded", function () {
    gsap.set("#gByBank", { autoAlpha: 0, x: 0, y: 50 })
    gsap.set("#gPressBtn1S", { autoAlpha: 0 })

    loadingLogo = gsap.timeline({ paused: false })
        .to(".preloader__logo-filter", { duration: 10, height: "0%", ease: "power1.out" }, ">")
    // .to(".preloader", { background: "rgba(255,255,255,0.3)", ease: "sine.out" }, "<")

    let mainContent = document.querySelector(".main_content")
    document.body.classList.add("DOMLoaded")
    mainContent.classList.add("DOMLoaded")

})

window.addEventListener("load", function () {
    document.body.classList.add("ContentLoaded")


    loadingLogo.paused(true)
    preloaderFun()

    let tlTimer
    window.addEventListener("resize", function () {
        clearTimeout(tlTimer);
        preloaderTl.kill()
        loadedLogo.kill()
        if (preloaderSt) preloaderSt.kill()

        tlTimer = setTimeout(function () {
            gsap.set(logoLoader, { x: 0, y: 0, scale: 1 })
            preloaderFun()
        }, 100)

    })

})

